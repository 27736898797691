.pricing{
// background:linear-gradient(180deg,rgb(208, 121, 255),rgb(0, 0, 0));
height: 100vh;
color:rgb(255, 255, 255);
background-color: rgb(21, 21, 21);

    .wrapper{
        
        max-width: 1366px;
        margin: auto;

        .navText{
            padding-top: 10px;
        height:100px;
        top:-50%;
        text-align: center;
        font-size: 35px;
        h2{
            .link{
                text-decoration: none;
                color: rgb(255, 255, 255);
            }
        }

        }

        .textContainer{
            text-align: center;
            display: flex;
            flex-direction: column;
            gap:10px;
            

            .yt{
                font-size: 25px;
                background:linear-gradient(15deg,red,white);
                color:white;
                border-radius: 10px;
                padding: 3px;
            }
            span{
                font-size: 20px;
            }

        }

    .plaincontainerwrapper{
       
        border-width: 3px;
        border-style: solid;
        border-image: 
          linear-gradient(
            to bottom, 
            red, 
            rgba(0, 0, 0, 0)
          ) 1 100%;
        max-width: 1000px;
        margin: auto;
        .plansContainer{ 
            margin:120px ;
            display: flex;
            justify-content: center;
            align-items: center;
            gap:40px;

            .monthlyplan{
                flex:1;
                
                
            }
            .yearlyplan{
                flex:1;
            }

                .includes{
                    display: flex;
                    flex-direction: column;
                    gap:25px;
                    ul li {
                        list-style: none;
                        text-align: center;
                        padding:2px;
                        font-size: 20px;
                    }

                    h2, button{
                        background-color: rgb(58, 56, 56);
                        color:white;
                        border:solid rgb(0, 0, 0) 1px;
                        border-radius: 10px;
                        padding:10px;
                        text-align: center;
                    }

                    button{
                        border:dotted rgb(255, 255, 255) 1px;
                        cursor: pointer;
                        background: transparent;
                        padding:12px;
                        font-size: 22px;
                        color: black;
                        font-weight: 1000   ;
                        background-image: linear-gradient(45deg, #ff0000, #ffffff);
                background-size: 100%;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                        
                    }
                }

            

        }

        .bottomtext{
            text-align: center;
        }


    }



    }
}