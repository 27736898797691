.featuress {
    background-color: rgb(21, 21, 21);
    color: white;
    scroll-behavior: smooth;
    .wrappers {
        max-width: 1366px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .heading {
            h2{
            font-size: 50px;
            font-family: "Abril Fatface", serif;
            font-weight:400;
            font-style: normal;
            // background-image: linear-gradient(45deg, #e40707, #d6a4fd);
            //     background-size: 100%;
            //     -webkit-background-clip: text;
            //     -webkit-text-fill-color: transparent; 
            //     -moz-background-clip: text;
            //     -moz-text-fill-color: transparent;
            }
            height: 150px;
            

            .progrss {
                height: 5px;
                background-color: white;
            }
        }

        // ----------_______________TITLE CONTAINER___________---------
        .titlecontainer {
            margin-bottom: 120px;
            display: flex;
            gap: 50px;
            align-items: center;

            .imgagecontainer {
                height: 50%;
                width: 50%;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    border-radius: 20px;
                }
            }

            .textContainer {
                height: 50%;
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                h2 {
                    font-family: "Abril Fatface", serif;
                    font-size: 50px;
                    color: rgb(150, 150, 150);
                }

                p {
                   
                    
                }
            }
        }

        // ----------_______________DESCRIPTION CONTAINER___________---------

        .descriptioncontainer {
            margin-bottom: 120px;
            display: flex;
            gap: 50px;
            align-items: center;

            .imgagecontainer {
                height: 50%;
                width: 50%;
                overflow: hidden;

                video {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    border-radius: 20px;
                }
            }

            .textContainer {
                height: 50%;
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                h2 {
                    font-size: 50px;
                    font-family: "Abril Fatface", serif;
                    color: rgb(150, 150, 150);
                }

                p {

                }
            }

        }

        // ----------_______________TAGS CONTAINER___________---------

        .tagscontainer {
            margin-bottom: 120px;
            display: flex;
            gap: 50px;
            align-items: center;

            .imgagecontainer {
                height: 50%;
                width: 50%;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    border-radius: 20px;
                }
            }

            .textContainer {
                height: 50%;
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                h2 {
                    font-family: "Abril Fatface", serif;
                    font-size: 50px;
                    color: rgb(150, 150, 150);
                }

                p {
                    
                }
            }

        }


    }
}