.summary{

    .navText{
        .smalltext{
            margin-top: -15px;
            
            .highlighttext{
                // background: linear-gradient(90deg,black,white);
                color:rgb(0, 0, 0);
                font-size: 20px;
                background-image: linear-gradient(45deg, #e40707, #d6a4fd);
                background-size: 100%;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
            }
        }
        
        padding-top: 10px;
        height:100px;
        top:-50%;
        text-align: center;
        font-size: 35px;
        h2{
            .link{
                text-decoration: none;
                color: black;
            }
        }
       
    }
    
    .inputcontrollers{
        width: 100%;
        display: flex;
        justify-content: center;
        gap:20px;
        padding:10px;
        .input{
            width: 400px;
            padding:10px;
            border-radius: 10px;   
        }
        .button{
            padding:10px;
            background: transparent;
            border-radius: 10px;
            width:200px;
            font-size: 20px;
            font-weight: 25;
            cursor: pointer;
            background-color: rgb(0, 0, 0);
            color:white
        }
    }

    .summarizepara{
        display: flex;
        flex-direction: column;
        gap:20px;
        font-size: 20px;
        padding:20px;
        max-width: 1366px;
        margin:auto;
    }
}