.navbar{

    background-color: rgb(0, 0, 0);
    // background: linear-gradient(180deg,rgb(255, 255, 255),rgb(0, 0, 0));
    color:white;
    
    .wrapper{
    color:rgb(255, 255, 255);
    height: 100px;
    max-width: 1366px;
    margin:auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .right{
      
        .links{

            .btn{
                margin-top: 5px;
                border: none;
                background-color: transparent;
                color:white;
                font-size: 15px;
                cursor: pointer;
                scroll-behavior: smooth;
            }
            

              .pricinglink{
                text-decoration:none;
                color:rgb(255, 255, 255);
                
            }
            .link{
                text-decoration:none;
                color:rgb(255, 255, 255);
                // border: solid black 1px;
               
                padding:8px;
                font-size: 20px;
                border-radius: 10px;
                // background: linear-gradient(-20deg,rgb(255, 14, 191),rgb(35, 29, 37));
                background-image: linear-gradient(45deg, #e40707, #d6a4fd);
                    background-size: 100%;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent; 
                    -moz-background-clip: text;
                    -moz-text-fill-color: transparent;
            } 
        display: flex;
        gap:20px;
    }
    }
}
}