.contactcontainer {
    height: 100vh;

    .wrapper {
        width: 800px;
        margin: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 15px;
        overflow: hidden;


        .navText {
            padding-top: 10px;
            height: 100px;
            top: -50%;
            text-align: center;
            font-size: 35px;

            h2 {
                .link {
                    text-decoration: none;
                    color: rgb(0, 0, 0);
                }
            }

        }

        .contact{
            display: flex;
            flex-direction: column;
            gap:20px;
            h1{
                font-size: 50px;
            }

            p{
                font-size: 25px;
            }
        }
        
        .sociallinks{
            display: flex;
            flex-direction: column;
            gap:20px;


            h2 {
                .link {
                    text-decoration: none;
                    color: rgb(0, 0, 0);
                }
            }
        }



    }
}