.terms{
    height: 100vh;

.wrapper{
    
    width: 1000px;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:20px;
    font-size: 15px;
    overflow: hidden;
    
    .navText{
        padding-top: 10px;
    height:100px;
    top:-50%;
    text-align: center;
    font-size: 35px;
    h2{
        .link{
            text-decoration: none;
            color: rgb(0, 0, 0);
        }
    }
    
    }

    .contactus{
        margin-bottom: 20px;
    }

    }
}