.howtouse {

    background-color: rgb(21, 21, 21);
    color: white;

    .wrapper {
        max-width: 1366px;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 100px;
        align-items: center;

        .mainheading{
            font-family: "Abril Fatface", serif;
            margin-top: 20px;
            font-size: 40px;
            background-image: linear-gradient(45deg, #ff0000,#d6a4fd);
                background-size: 100%;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
            .progress{
                background-color: white;
                height: 8px;
            }
        }

        .uploadcontainer {
            
            display: flex;
            gap: 50px;

            .textcontainer {
                flex: 1;
                display: flex;
                align-items: center;

                font-family: "Abril Fatface", serif;
                font-size: 50px;
                color: rgb(150, 150, 150);
                h3{
                    span{
                        color:white;
                    }
                }
            }

            .imagecontainer {
                flex: 2;
                overflow: hidden;
                

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    border-radius: 20px;
                }
            }
        }

        .unlistedcontainer {
            display: flex;
            gap: 50px;
            .textcontainer {
                flex: 1;
                display: flex;
                align-items: center;

                font-family: "Abril Fatface", serif;
                font-size: 50px;
                color: rgb(150, 150, 150);

                h3{
                    span{
                        color:white;
                    }
                }
            }

            .imagecontainer {
                flex: 1;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    border-radius: 20px;
                }
            }
        }

        .checkforcaptions {
            display: flex;
            gap:20px;
            .textcontainer {
                flex: 1;
                display: flex;
                align-items: center;

                font-family: "Abril Fatface", serif;
                font-size: 50px;
                color: rgb(150, 150, 150);

                h3{
                    span{
                        color:white;
                    }
                }
            }

            .imagecontainer {
                flex: 2;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    border-radius: 20px;
                }
            }
        }

        .copylinkcontainer {
            margin-bottom: 80px;
            display: flex;
            gap:50px;
            .textcontainer {
                flex: 1;
                display: flex;
                align-items: center;

                font-family: "Abril Fatface", serif;
                font-size: 50px;
                color: rgb(150, 150, 150);

                h3{
                    span{
                        color:white;
                    }
                }
            }

            .imagecontainer {
                flex: 1;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    border-radius: 20px;
                }
            }
        }
    }
}