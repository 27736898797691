.footer{
    // background:linear-gradient(90deg,black,white);
    background-color: black;
    color:rgb(255, 255, 255);
    // max-width: 1366px;
    // margin:auto;
    

    .contact{
        height: 50px;
        padding:20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap:50px;
        h3{
            .link {
                text-decoration: none;
                color: rgb(255, 255, 255);
            }
        }
    }
}