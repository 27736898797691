.home{
    scroll-behavior: smooth;
    background-color: rgb(21, 21, 21);
    // background: linear-gradient(65deg,black,white);
    color:white;
    .wrapper{
    height: calc(100vh - 100px);
    max-width: 1366px;
    margin: auto;
   
    
    .textContainer{ 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-width: 3px;
        border-style: solid;
        border-image: 
          linear-gradient(
            to bottom, 
            red, 
            rgba(0, 0, 0, 0)
          ) 1 100%;

          
        .small{
            font-size: 30px;
        }

        .title{
        //     background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(40, 39, 39));
        //   -webkit-background-clip: text;
        //   -webkit-text-fill-color: transparent;

            font-size: 50px;
            font-weight: 500;   ;
        }
    }
    .checkbox{ 

        .usernameContainer{
            margin-top: 20px;
            display: flex;
            gap:20px;
            text-align: center;
            justify-content: center;
            align-items: center;
            
            .username{

                font-size: 20px;
            }
            .button{
                padding:10px;
                background: transparent;
                border-radius: 10px;
                width:180px;
                font-size: 18px;
                font-weight: 20000;
                cursor: pointer;
                background-color: rgb(0, 0, 0);
                color:rgb(0, 0, 0);
                background-image: linear-gradient(45deg, #ff0000, #ffffff);
                background-size: 100%;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
            }


        }
            
        .inputcontrollers{
            width: 100%;
            display: flex;
            // align-items: center;
            justify-content: center;
            gap:20px;
            padding:10px;
            position: relative;

            .error{
                position: absolute;
                right: 190px;
                top:20px;
                z-index: 1000;
                color:red;
            }
            .input{
                width: 400px;
                padding:10px;
                border-radius: 10px;   
            }
            .button{
                padding:10px;
                background: transparent;
                border-radius: 10px;
                width:120px;
                font-size: 18px;
                font-weight: 20000;
                cursor: pointer;
                background-color: rgb(0, 0, 0);
                color:rgb(0, 0, 0);
                background-image: linear-gradient(45deg, #ff0000, #ffffff);
                background-size: 100%;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
            }
        }
    
}

    .herosection{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap:40px;
        .lefttextcontainer{
            // flex:1;
            display: flex;
            flex-direction: column;
            gap:5px;
            text-align: center;
            .spancontainer{
                display: flex;
                text-align: center;
                flex-direction: column;
                gap:10px;
            
            // justify-content: center;
            span{
                font-size: 30px;
                text-shadow: #ff0000;

            }
        }
            .bigtext{
                font-size: 50px;
                background-image: linear-gradient(45deg, #e40707, #d6a4fd);
                background-size: 100%;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                
            }
        }

        .videoContainer{
            // flex:2;
            margin-top: 10px;
            margin-bottom: 10px;
            height: 400px;
            width: 400;
            overflow: hidden;
            object-fit: contain;
            video{
                border-radius: 10px;
                width: 100%;
                height: 100%;
            }
        }

        .smalltext{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .smalltext{
                font-size: 20px;
            }

            .titlemid{
                font-size: 25px;
                color:red
            }

        
          }

       }
   }
    
}