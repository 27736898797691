.apinavbar{
    height: 100px;
    background-color: rgb(21, 21, 21);
    color: white;
    .wrapper{
        height: 100%;
        max-width: 1336px;
        margin: auto;
        display: flex;
        align-items:center;
        justify-content: space-between;
    

        button{
            border:solid rgb(255, 238, 0) 1px;
            border-radius: 12px;
            padding:8px;
            background-color: rgb(255, 255, 255);
            font-size: 20px;
            color:rgb(0, 0, 0);
            font-weight: 1000000!important;
            cursor: pointer;
        }
        .leftitems{
            display: flex;
            gap: 20px;
        }
        .rightitems{
            display: flex;
            justify-content: flex-end;
            gap: 20px;
        }
        h2{
            font-size: 20px;
            color:rgb(255, 255, 255);
            .link{
                text-decoration: none;   
                color:rgb(255, 255, 255);
                
            }
            .summarize{
                background-image: linear-gradient(45deg, #e40707, #d6a4fd);
                background-size: 100%;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
            }
        }
    }

}