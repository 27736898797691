.check{
    height: calc(100vh - 100px);
    background-color: rgb(21, 21, 21);
    color: white;
    .wrapper{
        height: 100%;
        max-width: 1366px;
        margin:auto;

        .copybtn{
            background-image: linear-gradient(45deg, #ffffff, #4012ca);
            background-size: 100%;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            border:none;
           
            cursor: pointer;
            font-size: 20px;
        }
        
    .navText{
        padding-top: 10px;
        height:100px;
        top:-50%;
        text-align: center;
        font-size: 35px;
        h2{
            .link{
                text-decoration: none;
                color: black;
            }
        }
       
    }

    .inputcontrollers{
        width: 100%;
        display: flex;
        justify-content: center;
        gap:20px;
        padding:5px;
        .input{
            width: 400px;
            padding:10px;
            border-radius: 10px;   
        }
        .button{
            padding:10px;
            background: transparent;
            border-radius: 10px;
            width:120px;
            font-size: 20px;
            font-weight: 25;
            cursor: pointer;
            background-color: rgb(0, 0, 0);
            color:white
        }
    }

    .titlecontainer{
        margin-top: 20px;
        overflow: hidden;
        display: flex;
        gap:20px;
        margin-bottom: 35px;
        
        
        .titles{

        .imgContainer{
            // flex:1;
            width: 420px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .titlebox{
            // background-color: bisque;
            width: 420px;
        span{
            // background-color: bisque; 
            margin-top: 20px;
            font-size: 20px;
   
            overflow: hidden;
          }
         }
       }
      }

      .desContainer{
        .des{
            width: 100%;
            display: flex;
            gap:5px;
            font-size: 25px;
            margin-bottom: 10px;
        h3{
            background-image: linear-gradient(45deg, #e40707, #d6a4fd);
                background-size: 100%;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
            font-family: "Abril Fatface", serif;
            font-weight:400;
            font-style: normal;
            // border:solid rgb(115, 0, 255) 1px; 
            padding:3px;
            border-radius: 10px;
            margin-bottom:5px ;
            background-color:rgb(115, 0, 255) ;
        }
        span{
            font-size: 15px;
            // font-weight: 15;
        }
        }
        
      }

      .tagsContainer{
        margin-top: 20px;
        // display: flex;
        gap:5px;
        
        .tag{
            margin-bottom: 10px;
            font-size: 25px;
            display: flex;
            gap:5px;
            h3{
                background-image: linear-gradient(45deg, #e40707, #d6a4fd);
                background-size: 100%;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                background-color:rgb(115, 0, 255) ;
                font-family: "Abril Fatface", serif;
            font-weight:400;
            font-style: normal;
            // border:solid rgb(115, 0, 255) 1px; 
            padding:3px;
            border-radius: 10px;
            }
        }
        .tagsbox{
            max-width: 100px;
            white-space: initial;
           display:flex;
           gap:5px;
           
            .span{
                color:rgb(0, 0, 0);
                display:flex;
                align-items: center;
                justify-content: center;
                background-color:rgb(255, 255, 255);
                width:100%;
                // white-space: nowrap;
                // height: 100px;
                padding:5px;
                font-size: 15px;
                outline: 1px solid black;
                border-radius: 15px;
                
            }
        }
        
      }
   } 
}